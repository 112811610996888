

import { defineComponent } from "vue";
import JSZip from 'jszip'

const MAX_DISPLAY_FILE = 10;
const MAX_DOWNLOAD_FILE_CHUNK = 1000;
 
const forJoin = (
                path:string ,
                obj:any ) :any => {
   
   const paths =[];
   for( let key in obj ){
			if ( obj[key] !== "file" ){
      	
         paths.push( 
         forJoin (path + "/" + key,obj[key]));
        
      } else{
          paths.push( path+"/"+key);
      }
   }
   return paths.flat(Infinity);
}

const getData = async ( obj :any) => {

   await new Promise( ( s:any ) => setTimeout(s, 300));
  return new Promise( (resolve:any, reject:any) => {
        const xhr = new XMLHttpRequest();
        xhr.open('GET', obj.url , true);
        xhr.responseType = "blob";
        xhr.onload = async (e:any) => {
      // 取得した画像データが e.currentTarget.response にある
        resolve(
            {
                filename : obj.filename,
               path : obj.path  ,
             data :e.currentTarget.response
            }
      );
    };
    xhr.send();
  });
};

const generateZip =async (images:any) => {

  return new Promise((resolve:any, reject:any) => {
    const zip = new JSZip();
    images.forEach((imageData:any, i:any) => {
      zip.file(imageData.path + imageData.filename , imageData.data);
    });
    zip.generateAsync({ type: "blob" }).then(resolve);
  });
};

export default defineComponent({
  name: "FileListView",
 // components:  {  } ,
  data : function() {return { 
        MAX_DOWNLOAD_FILE_CHUNK : MAX_DOWNLOAD_FILE_CHUNK ,
        filenames : [],
        directory : "",
        directorys : {},
        currentDirectory : {},
        displayFiles : [],
        pagenation : {   max: 0  } 
  } },
  computed: {},
  mounted : async function(){
    this.$emit('show-header');      
    
    //    userId = "59fd5771-c971-4cac-9d69-439ffbb6484d";
    // alert( JSON.stringify (res.data.filenames) );
    const  res = await fetch(process.env.VUE_APP_API_URL + "GetDataFileListHttpTrigger"+"?dirname=\"\""  + ( process.env.VUE_APP_API_CODE!.replace('?','&') ) );
    
            const json =  await res.json();
           
            this.filenames = json.filenames;         
                    	
               for( var i=0 ; i < (this.filenames as string[]).length ; i++ ){
                  var ds = (this.filenames[i] as string ).split('/');
                  if ( 1 < ds.length){
                  		var filename:any =ds.pop();
                  		var path:any = ds.shift();
                  	
                  	var tmp:any = this.directorys;    
                  				
                  		while ( path ) {
                  				if(! tmp [ path ] ){
                  							
                  					tmp [ path ] =  {} ;
                  					tmp = tmp [ path ];
                  				}else{
                          	tmp = tmp [ path ];
                          }
                  				path = ds.shift();
                  		}
                  		
                  		tmp[filename]="file";
                  		
                  }else{
                      var tm:any = this.directorys;    
                       tm[ ds[0] as string  ]="file";
                  }
            }
            
            this. currentDirectory = this.directorys;
           // console.log(this.directorys);
            this.showFiles(1);
   },
  methods: {  
      async  prevDirectory( this:any ,  pos :number ){

            const dirs = this.directory.split('/')
            var dir = "";
            for( var i=0 ; i <pos  ; i++){
                if( i!==0 ){
                    dir +=  "/"+ dirs[i];
                }else{
                    dir += dirs[i];
                }
            }
            this.directory =  (dir==="")? dir : dir+"/"  ;
            let tmparr =this.directory.split('/');
             this. currentDirectory = this.directorys;
             //alert(tmparr);
            var d = this. directorys;
            for (  var j =0;  j <tmparr.length ; j++ ){
                if(tmparr[j]  ){
                    
                    if ( d[ tmparr[j] ] ){ 
                      //  this.directory = tmparr[j].join('/');
                      
                        d = d[ tmparr[j] ] ;
                    }
               }
            }
            
             this. currentDirectory = d ;
            
            this.showFiles(1);       
      
      },
    async  nextDirectory( this:any , dirname :string ){
            this.currentDirectory = this.currentDirectory [dirname];
            
            this.directory += dirname +   "/";
            this.showFiles(1);       
      
      },/*
      function getFileDirectorys(){
          
            let  directorys = [];
            for( var i=0 ; i < this.filenames.length ; i++ ){
                    filenames[i].split();
            }
          
      },*/
      showFiles( this : any , n :number ){
        var showCount=0;
        var pos=0;
        if (  1 < n  ){
            pos =( n -1)* MAX_DISPLAY_FILE ; 
            showCount = ( n -1)* MAX_DISPLAY_FILE;
        }
        this.displayFiles = [] ;
        var max = 0;
        for( var i=0 ; i < this.filenames.length ; i++ ){
            
                if( this.directory === ""){
                  if( pos <= i  && showCount !== MAX_DISPLAY_FILE * n ){ 
                    var isExistFile = false; // ディレクトリ表時をまとめる為。
                    for ( var j=0; j <  this.displayFiles.length ; j ++ ){
                         if ( this.filenames[i] === this.displayFiles[j] ){
                              isExistFile = true;
                         }
                      }
                    if( ! isExistFile){
                        this.displayFiles.push( this.filenames[i]);
                        showCount++;
                    }             
                  } 
                  max++;
                } else {
                   
                    var  filename = this.filenames[i].split(this.directory);
                    if( pos <= i  && 2 === filename.length ){
                        if( showCount !== MAX_DISPLAY_FILE * n ){ 
                            var isExistFil = false; // ディレクトリ表時をまとめる為。     
                            for ( var k=0; k <  this.displayFiles.length ; k ++ ){
                                    if ( filename[1] === this.displayFiles[k] ){
                                        isExistFil = true;
                                    }   
                            }
                            if(!isExistFil){
                                this.displayFiles.push( filename[1] );
                                showCount++;
                            }
                        }
                          max++;
                    }
                }
            }
         const maxpage =  Math.ceil( max / MAX_DISPLAY_FILE);
         this.pagenation.max = ( 0< maxpage ) ? maxpage : 1;    
      
      },
      downloadAll (this:any){
          
            // console.log(JSON.stringify(this.currentDirectory));
            
              this.$emit('show-flash',{"message":"データのダウンロードには時間がかかる場合がございます。しばらくお待ちくださいませ。","type": "warning"});          
            
            
            let paths = forJoin("",this.currentDirectory);
            
            
            const imgUrlsList:any[] = [[]];
            var index = 0;

            var count = 0;
            for(  var i=0;i< paths.length; i++){
                var tmp =paths[i].split('/');
               var p = tmp.pop();tmp.shift();
                tmp = tmp.join('/')+"/";
               //alert(p);
              var pa = this.directory.replace("/root","");
              var pat =  ("/file/"+ p  + "?directory="+ pa +tmp ).replaceAll('//','/');
              imgUrlsList[index].push(  
                { 
                    filename : p , 
                    path : pa + tmp ,
                    url : process.env.VUE_APP_API_URL + pat + ( process.env.VUE_APP_API_CODE!.replace('?','&') )
                }
              );
              count ++ ;
              if(count > MAX_DOWNLOAD_FILE_CHUNK+1){
                 index++ ;
                 count = 0 ; 
                 imgUrlsList.push([]);
              } 
          //      console.log(process.env.VUE_APP_API_URL + "/file/"+ p  + "?directory="+ (tmp+ process.env.VUE_APP_API_CODE!.replace('?','&') ));
            }
            
         //   console.log(paths); 
            for (  var j =0; imgUrlsList ; j++ ){
                const imgUrls = imgUrlsList[j];
                Promise.all(
                        imgUrls.map(async (o:any) => {return await getData(o);})
                    ).then(async (blobs:any) => {

                      await generateZip(blobs).then(async (zip:any) => {
                      console.log(URL.createObjectURL(zip))
                      const href = URL.createObjectURL(zip);
                      const link = document.createElement('a')
                      link.download = 'sample.zip'
                      link.href = href;
                      link.click()
                });
            });   
        }   
      },
      getDownloadURL ( filename : string ){

          return  process.env.VUE_APP_API_URL + "/file/"+filename + "?directory="+ (this.directory+ process.env.VUE_APP_API_CODE!.replace('?','&') ) ;
      
      }
    }
});

